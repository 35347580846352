import React from "react";

const WeightMeter = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FEF0ED" />
      <path
        d="M18 30H22C27 30 29 28 29 23V17C29 12 27 10 22 10H18C13 10 11 12 11 17V23C11 28 13 30 18 30Z"
        stroke="#F37458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.25 16.2899C22.26 13.6299 17.74 13.6299 14.75 16.2899L16.93 19.7899C18.68 18.2299 21.32 18.2299 23.07 19.7899L25.25 16.2899Z"
        stroke="#F37458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WeightMeter;
