import React from "react";

const FastDeliveryIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3128_1626)">
          <path
            d="M23.2792 10.0936C23.2792 14.6847 19.5573 18.4066 14.9662 18.4066C10.3752 18.4066 6.65332 14.6847 6.65332 10.0936C6.65332 5.50236 10.3752 1.78052 14.9662 1.78052C19.5573 1.78052 23.2792 5.50236 23.2792 10.0936V10.0936Z"
            fill="#F6CC46"
          />
          <path
            d="M16.4124 20.576H4.8457V11.6312C4.8457 10.782 5.53403 10.0936 6.38328 10.0936H14.8745C15.7237 10.0936 16.4122 10.782 16.4122 11.6312V20.576H16.4124Z"
            fill="white"
          />
          <path
            d="M16.4118 20.9365H4.84589C4.6461 20.9365 4.48438 20.775 4.48438 20.5752V11.6311C4.48438 10.5838 5.33624 9.73215 6.38355 9.73215H14.8743C15.9214 9.73215 16.7733 10.5838 16.7733 11.6311V20.5752C16.7731 20.775 16.6116 20.9365 16.4118 20.9365ZM5.20719 20.2137H16.0503V11.6311C16.0503 10.9828 15.5225 10.455 14.8741 10.455H6.38355C5.73498 10.455 5.20719 10.9828 5.20719 11.6311V20.2137Z"
            fill="#4F4F4F"
          />
          <path
            d="M22.9174 20.5757H16.4111V13.7079H19.7535C20.5668 13.7079 21.3244 14.1152 21.7649 14.7888L22.9174 16.5523V20.5757Z"
            fill="white"
          />
          <path
            d="M22.9176 20.9371H16.4113C16.2115 20.9371 16.0498 20.7755 16.0498 20.5757V13.7079C16.0498 13.5081 16.2115 13.3464 16.4113 13.3464H19.7537C20.6938 13.3464 21.5585 13.8116 22.0675 14.5911L23.22 16.3545C23.2584 16.4133 23.2789 16.4822 23.2789 16.5523V20.5757C23.2789 20.7755 23.1174 20.9371 22.9176 20.9371V20.9371ZM16.7727 20.2142H22.556V16.66L21.4625 14.9864C21.0873 14.4122 20.4482 14.0695 19.7537 14.0695H16.7727V20.2142Z"
            fill="#4F4F4F"
          />
          <path
            d="M16.412 20.5752H3.76172V20.2185C3.76172 19.4175 4.41112 18.7681 5.21211 18.7681H16.412V20.5752Z"
            fill="#F2F2F2"
          />
          <path
            d="M16.4121 20.9366H3.7619C3.56233 20.9366 3.40039 20.775 3.40039 20.5752V20.2183C3.40039 19.2194 4.21317 18.4066 5.21209 18.4066H16.4121C16.6119 18.4066 16.7734 18.5683 16.7734 18.7681V20.5752C16.7734 20.775 16.6119 20.9366 16.4121 20.9366ZM4.12321 20.2137H16.0506V19.1294H5.21209C4.61336 19.1294 4.12569 19.6154 4.12321 20.2137V20.2137Z"
            fill="#4F4F4F"
          />
          <path
            d="M21.3919 20.485C21.3919 21.389 20.6591 22.1218 19.755 22.1218C18.8512 22.1218 18.1182 21.389 18.1182 20.485C18.1182 19.5809 18.8512 18.8481 19.755 18.8481C20.6591 18.8481 21.3919 19.5809 21.3919 20.485Z"
            fill="#F6CC46"
          />
          <path
            d="M19.755 22.4834C18.6534 22.4834 17.7568 21.5868 17.7568 20.485C17.7568 19.3834 18.6534 18.4868 19.755 18.4868C20.8566 18.4868 21.7532 19.3834 21.7532 20.485C21.7532 21.5866 20.8566 22.4834 19.755 22.4834V22.4834ZM19.755 19.2097C19.052 19.2097 18.4797 19.7818 18.4797 20.485C18.4797 21.188 19.052 21.7603 19.755 21.7603C20.4582 21.7603 21.0303 21.188 21.0303 20.485C21.0303 19.7818 20.4582 19.2097 19.755 19.2097Z"
            fill="#4F4F4F"
          />
          <path
            d="M9.90672 14.0695H0.870327C0.67053 14.0695 0.508789 13.9077 0.508789 13.7079C0.508789 13.5081 0.67053 13.3464 0.870327 13.3464H9.90672C10.1063 13.3464 10.2683 13.5081 10.2683 13.7079C10.2683 13.9077 10.1063 14.0695 9.90672 14.0695Z"
            fill="#4F4F4F"
          />
          <path
            d="M20.3457 20.4849C20.3457 20.8115 20.081 21.0762 19.7544 21.0762C19.4278 21.0762 19.1631 20.8115 19.1631 20.4849C19.1631 20.1583 19.4278 19.8936 19.7544 19.8936C20.081 19.8936 20.3457 20.1583 20.3457 20.4849V20.4849Z"
            fill="#4F4F4F"
          />
          <path
            d="M10.5489 20.485C10.5489 21.389 9.81609 22.1218 8.91203 22.1218C8.00819 22.1218 7.27539 21.389 7.27539 20.485C7.27539 19.5809 8.00819 18.8481 8.91203 18.8481C9.81609 18.8481 10.5489 19.5809 10.5489 20.485Z"
            fill="#F6CC46"
          />
          <path
            d="M8.91224 22.4834C7.81046 22.4834 6.91406 21.5868 6.91406 20.485C6.91406 19.3834 7.81046 18.4868 8.91224 18.4868C10.0142 18.4868 10.9106 19.3834 10.9106 20.485C10.9106 21.5868 10.0142 22.4834 8.91224 22.4834V22.4834ZM8.91224 19.2097C8.20923 19.2097 7.63693 19.7818 7.63693 20.485C7.63693 21.188 8.20923 21.7603 8.91224 21.7603C9.61547 21.7603 10.1876 21.188 10.1876 20.485C10.1876 19.7818 9.61547 19.2097 8.91224 19.2097Z"
            fill="#4F4F4F"
          />
          <path
            d="M9.50373 20.4849C9.50373 20.8115 9.23899 21.0762 8.91241 21.0762C8.58603 21.0762 8.32129 20.8115 8.32129 20.4849C8.32129 20.1583 8.58603 19.8936 8.91241 19.8936C9.23899 19.8936 9.50373 20.1583 9.50373 20.4849V20.4849Z"
            fill="#4F4F4F"
          />
          <path
            d="M8.99788 10.3991C8.99788 12.1564 7.57344 13.5808 5.81622 13.5808C4.0592 13.5808 2.63477 12.1564 2.63477 10.3991C2.63477 8.6419 4.0592 7.21747 5.81622 7.21747C7.57344 7.21747 8.99788 8.6419 8.99788 10.3991Z"
            fill="#F6CC46"
          />
          <path
            d="M5.81643 13.9422C3.86272 13.9422 2.27344 12.3527 2.27344 10.3992C2.27344 8.44569 3.86272 6.8562 5.81643 6.8562C7.76994 6.8562 9.35963 8.44569 9.35963 10.3992C9.35963 12.3527 7.76994 13.9422 5.81643 13.9422ZM5.81643 7.57907C4.26148 7.57907 2.99631 8.84425 2.99631 10.3992C2.99631 11.9541 4.26148 13.2193 5.81643 13.2193C7.37158 13.2193 8.63655 11.9541 8.63655 10.3992C8.63655 8.84425 7.37158 7.57907 5.81643 7.57907Z"
            fill="#4F4F4F"
          />
          <path
            d="M5.12289 11.8675C5.04925 11.8675 4.97459 11.8448 4.91027 11.7978C4.74894 11.6804 4.71357 11.4545 4.83105 11.2932L5.56902 10.2812V8.81784C5.56902 8.61804 5.73076 8.4563 5.93035 8.4563C6.13015 8.4563 6.29189 8.61804 6.29189 8.81784V10.399C6.29189 10.4758 6.26748 10.5502 6.22239 10.6119L5.41534 11.7188C5.34461 11.816 5.23457 11.8675 5.12289 11.8675Z"
            fill="#4F4F4F"
          />
          <path
            d="M18.9424 14.7923V17.3224H21.3753C21.5799 17.3224 21.7079 17.1015 21.6066 16.9241L20.7625 15.4471C20.531 15.0421 20.1004 14.7923 19.6338 14.7923H18.9424Z"
            fill="#F6CC46"
          />
          <path
            d="M21.3753 17.684H18.9426C18.7428 17.684 18.5811 17.5222 18.5811 17.3224V14.7923C18.5811 14.5925 18.7428 14.4308 18.9426 14.4308H19.634C20.2289 14.4308 20.7815 14.7518 21.0765 15.2678L21.9203 16.745C22.0326 16.9406 22.0322 17.175 21.9187 17.3708C21.8049 17.5669 21.602 17.684 21.3753 17.684V17.684ZM19.3041 16.9611H21.2113L20.4487 15.6262C20.282 15.3348 19.9701 15.1536 19.634 15.1536H19.3041V16.9611Z"
            fill="#4F4F4F"
          />
          <path
            d="M7.73824 15.5151H3.03907C2.83948 15.5151 2.67773 15.3534 2.67773 15.1536C2.67773 14.9538 2.83948 14.7923 3.03907 14.7923H7.73824C7.93783 14.7923 8.09957 14.9538 8.09957 15.1536C8.09957 15.3534 7.93783 15.5151 7.73824 15.5151Z"
            fill="#4F4F4F"
          />
          <path
            d="M5.93062 16.961H0.870327C0.67053 16.961 0.508789 16.7993 0.508789 16.5995C0.508789 16.3997 0.67053 16.2379 0.870327 16.2379H5.93062C6.13042 16.2379 6.29216 16.3997 6.29216 16.5995C6.29216 16.7993 6.13042 16.961 5.93062 16.961Z"
            fill="#4F4F4F"
          />
        </g>
        <defs>
          <clipPath id="clip0_3128_1626">
            <rect
              width="22.7704"
              height="22.7704"
              fill="white"
              transform="translate(0.508789 0.770386)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default FastDeliveryIcon;
