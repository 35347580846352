import React from "react";

export const LocationIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.2711" cy="15.3814" r="15.1803" fill="#F5F5F5" />
      <g clipPath="url(#clip0_3128_1552)">
        <path
          d="M15.271 24.869C15.1257 24.8691 14.9854 24.8159 14.8765 24.7196C14.6021 24.4753 8.15527 18.6768 8.15527 13.0608C8.15527 9.10844 11.3479 5.89371 15.271 5.89371C19.1942 5.89371 22.3868 9.10844 22.3868 13.0608C22.3868 18.6768 15.9399 24.4753 15.6656 24.7188C15.557 24.8157 15.4165 24.8691 15.271 24.869Z"
          fill="#F6CC46"
        />
        <path
          d="M15.2706 16.9627C13.0908 16.9627 11.3174 15.1893 11.3174 13.0095C11.3174 10.8297 13.0908 9.05627 15.2706 9.05627C17.4504 9.05627 19.2238 10.8297 19.2238 13.0095C19.2238 15.1893 17.4504 16.9627 15.2706 16.9627Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3128_1552">
          <rect
            width="18.9753"
            height="18.9753"
            fill="white"
            transform="translate(5.7832 5.89371)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const HomeLocationIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.2711" cy="15.9468" r="15.1803" fill="#E8F1FF" />
      <g clipPath="url(#clip0_3128_1565)">
        <path
          d="M20.2522 15.2353C18.2904 15.2353 16.6943 16.8513 16.6943 18.8373C16.6943 21.579 19.774 24.2453 19.905 24.357C20.0013 24.4405 20.1246 24.4865 20.2521 24.4867C20.3796 24.4868 20.5029 24.441 20.5995 24.3577C20.7304 24.2453 23.8101 21.579 23.8101 18.8373C23.8101 16.8513 22.214 15.2353 20.2522 15.2353ZM20.2522 20.2163C19.4673 20.2163 18.8291 19.578 18.8291 18.7932C18.8291 18.0083 19.4673 17.37 20.2522 17.37C21.0371 17.37 21.6754 18.0083 21.6754 18.7932C21.6754 19.578 21.0371 20.2163 20.2522 20.2163Z"
          fill="#F6CC46"
        />
        <path
          d="M19.8602 12.6557L18.8291 11.6474V8.83102C18.8291 8.6423 18.7542 8.46131 18.6207 8.32786C18.4873 8.19442 18.3063 8.11945 18.1175 8.11945C17.9288 8.11945 17.7478 8.19442 17.6144 8.32786C17.4809 8.46131 17.406 8.6423 17.406 8.83102V10.2556L14.7013 7.61067C14.5683 7.48072 14.3898 7.40796 14.2039 7.40796C14.018 7.40796 13.8394 7.48072 13.7065 7.61067L7.05042 14.1173C6.89964 14.2661 6.79649 14.4564 6.75407 14.664C6.71165 14.8715 6.73187 15.087 6.81216 15.2831C6.89246 15.4791 7.0292 15.6469 7.20504 15.7651C7.38087 15.8832 7.58786 15.9465 7.79971 15.9468H8.86707V19.8604C8.86707 20.841 9.66475 21.6394 10.646 21.6394H14.5597C14.7484 21.6394 14.9294 21.5644 15.0628 21.431C15.1963 21.2975 15.2712 21.1165 15.2712 20.9278C15.2712 20.7391 15.1963 20.5581 15.0628 20.4246C14.9294 20.2912 14.7484 20.2162 14.5597 20.2162H10.646C10.5517 20.2162 10.4612 20.1787 10.3944 20.112C10.3277 20.0453 10.2902 19.9548 10.2902 19.8604V15.2352C10.2902 15.0465 10.2153 14.8655 10.0818 14.732C9.94836 14.5986 9.76737 14.5236 9.57865 14.5236H8.67139L14.2039 9.11423L18.8654 13.6726C19.0003 13.8045 19.182 13.8774 19.3706 13.8754C19.5592 13.8733 19.7393 13.7964 19.8712 13.6615C20.0031 13.5267 20.0761 13.345 20.074 13.1564C20.072 12.9677 19.995 12.7877 19.8602 12.6557Z"
          fill="#1777FE"
        />
      </g>
      <defs>
        <clipPath id="clip0_3128_1565">
          <rect
            width="17.0778"
            height="17.0778"
            fill="white"
            transform="translate(6.73242 7.40796)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
