import React, { useEffect, useState } from "react";
import {
  HomeLocationIcon,
  LocationIcon,
} from "@/icons/website-icon/LocationIcon";
import { FaAddressCard, FaCheckCircle, FaChevronDown } from "react-icons/fa";
import { ProductBox } from "@/icons/website-icon/ProductBox";
import WeightMeter from "@/icons/website-icon/WeightMeter";
import {
  Button,
  Input,
  Select,
  SelectItem,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import { ButtonArrowRight } from "@/icons/website-icon/ButtonArrowRight";
import {
  checkIsZipCodeRequired,
  GetCountryList,
} from "@/static/CourierRequirements";
import {
  useGetProductCategoryQuery,
  useGetShipPriceMutation,
} from "./ship.api";
import { Controller, useForm } from "react-hook-form";
import Image from "next/image";
import useDebounceFunction from "@/hooks/useDebounce";
import { useGetZipCodeMutation } from "@/features/shipping/shipping.api";
import { FaCircleXmark } from "react-icons/fa6";
import { useAppSelector } from "@/config/hooks";
import { RootState } from "@/config/store";
import { useDispatch } from "react-redux";
import {
  createBox,
  createShippingInformationForm,
} from "@/features/shipping/Shipping.slice";
import ShippingMethod from "./ShippingMethod";
import ReactFlagsSelect from "react-flags-select";
import { getPhoneCode } from "@/utils/getCountry";

const Ship = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [largePackage, setLargePackage] = useState(false);
  const [weightType, setWeightType] = useState("kg");
  const shippingState = useAppSelector((state: RootState) => state.shipping);
  const [zipCodeRequirements, setZipCoderequirements] = useState({
    sebderZipCode:
      checkIsZipCodeRequired(
        shippingState?.data?.shippingInformation?.form?.sender?.country_code
      ) ?? true,
    receiverZipCode:
      checkIsZipCodeRequired(
        shippingState?.data?.shippingInformation?.form?.receiver?.country_code
      ) ?? true,
  });

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues,
    setValue,
    control,
  } = useForm();
  const [
    getSenderZipCode,
    {
      isLoading: senderZipCodeLoading,
      isError: senderZipCodeError,
      isSuccess: senderZipCodeSuccess,
      data: senderZipCodeResponse,
    },
  ] = useGetZipCodeMutation();

  const [
    getReceiverZipCode,
    {
      isLoading: receiverZipCodeLoading,
      isError: receiverZipCodeError,
      isSuccess: receiverZipCodeSuccess,
      data: receiverZipCodeResponse,
    },
  ] = useGetZipCodeMutation();


  const[isZipCodeEmpty,setZipCodeEmpty] = useState({
    sender_zip_code:true,
    receiver_zip_code:true,
  })
 

  if (senderZipCodeSuccess) {
    setValue("sender_zip_code", senderZipCodeResponse?.data?.full_address);
    setValue("sender", {
      ...senderZipCodeResponse?.data,
    });
  }

  if (receiverZipCodeSuccess) {
    setValue("receiver_zip_code", receiverZipCodeResponse.data?.full_address);
    setValue("receiver", {
      ...receiverZipCodeResponse.data,
    });
  }
  const { data: productType, isSuccess: isProductTypeSuccess } =
    useGetProductCategoryQuery("");
  const [
    getShipPrices,
    {
      isLoading: isLoadingCourierList,
      isSuccess: isSucessShipPriceList,
      data: courierList,
      error: registrationError,
    },
  ] = useGetShipPriceMutation<any>();

  const handleWeightType = (value: string) => {
    setWeightType(value);
  };

  const handleSubmitCalculator = (inputData: any) => {
    dispatch(createShippingInformationForm(inputData));
    let boxes: Array<any> = [
      {
        box_id: "0",
        name: "Box 1",
        height: inputData?.box_height ? inputData?.box_height : "1",
        width: inputData?.box_width ? inputData?.box_width : "1",
        length: inputData?.box_length ? inputData?.box_length : "1",
        weight: inputData?.weight ? inputData?.weight : "1",
        status: "progress",
        items: [{ id: 1, name: "", quantity: "", unit_price: "" }],
      },
    ];
    dispatch(createBox(boxes));
    getShipPrices({
      ...inputData,
      box_dimension: boxes,
      weight_unit: weightType,
      box_quantity: 1,
      box_dimension_type: "1",
      box_information: [],
      insurance: "no",
      product_value: 0,
      premium_value: 0,
      delivery_type: "Commercial",
    });
    console.log(inputData);
    // reset();
    // setZipCodeEmpty({
    //   receiver_zip_code: true,
    //   sender_zip_code: true
    // })
    // setValue("sender_country","US")
    // setValue("receiver_country","US")
    // setZipCoderequirements({
    //   receiverZipCode: true,
    //   sebderZipCode: true,
    // })
  };
  useEffect(() => {
    if (isLoadingCourierList) {
      onOpen();
    }
  }, [isLoadingCourierList]);

  const handleChangeZipCode = useDebounceFunction(
    (value: string, fieldName: string) => {
      const zipCodeMutation: any =
        fieldName == "sender_country" ? getSenderZipCode : getReceiverZipCode;

      zipCodeMutation({
        country_code: 234,
        zip_code: value,
        country_short_code: getValues(fieldName),
      });

      // set empty zip input 
      if(value){
        if(fieldName=="sender_country"){
          setZipCodeEmpty({...isZipCodeEmpty,sender_zip_code:false})
        }else if(fieldName=="receiver_country"){
          setZipCodeEmpty({...isZipCodeEmpty,receiver_zip_code:false})
        }
      }else{
        if(fieldName=="sender_country"){
          setZipCodeEmpty({...isZipCodeEmpty,sender_zip_code:true})
        }else if(fieldName=="receiver_country"){
          setZipCodeEmpty({...isZipCodeEmpty,receiver_zip_code:true})
        }
      }
    },
    1000
  );

  return (
    <>
      <form
        onSubmit={handleSubmit(handleSubmitCalculator)}
        className="mt-7 lg:mt-16 lg:min-w-[488px]"
      >
        {/*<div className="my-3 lg:my-5">
          <SelectCountry
            value={senderCountryVal}
            label={"From Country"}
            onChange={(e: string) => setSenderCountryVal(e)}
            options={countries}
            setCountryShortCode={setSenderCountryCode}
            icon={<LocationIcon />}
          />

          {errors.sender_country && (
            <p className="text-[10px] font-plus_jakarta text-red-500 px-3 py-[2px]">
              This field is required
            </p>
          )} 
        </div>*/}
        <div className="my-3 lg:my-5">
          <div className="bg-white rounded-[10px] flex items-center px-2 border-1 border-[#EEEEEE]">
            <div className="w-[28px] h-[28px] lg:h-[40px] lg:w-[40px]  rounded-full bg-[#f0ddb0] flex items-center justify-center">
              <HomeLocationIcon />
            </div>
            <div className="flex items-center">
              <Controller
                name="sender_country"
                control={control}
                defaultValue={
                  shippingState?.data?.shippingInformation?.form?.sender
                    ?.country_code ?? "US"
                }
                render={({ field: { onChange, value } }) => (
                  <ReactFlagsSelect
                    key={"sender_country"}
                    selected={value}
                    onSelect={(countryCode) => {
                      onChange(countryCode);
                      let checkZipCodeStatus =
                        checkIsZipCodeRequired(countryCode);
                      console.log(checkZipCodeStatus);
                      setZipCoderequirements((prev: any) => ({
                        ...prev,
                        sebderZipCode: checkZipCodeStatus,
                      }));
                      setValue("sender", {
                        country: countryCode,
                        country_code: countryCode,
                        sender_zip_code: "",
                      });
                      setValue("sender_zip_code", "");

                      // Add any additional logic you need here
                    }}
                    fullWidth={!zipCodeRequirements?.sebderZipCode}
                    showSelectedLabel={!zipCodeRequirements?.sebderZipCode}
                    placeholder=" "
                    searchable={true}
                    className="bg-[#fff] rounded-sm focus:outline-none border-0 country_selection_no_border"
                  />
                )}
              />
         
              {zipCodeRequirements?.sebderZipCode && (
                <Input
                  {...register("sender_zip_code", {
                    required: "Zip code is required",
                  })}
                  variant="bordered"
                  type="text"
                  aria-label="Sender/From"
                  isRequired
                  onChange={(e) =>
                    handleChangeZipCode(e.target.value, "sender_country")
                  }
                  autoComplete="off"
                  placeholder="Sender zip code"
                  endContent={
                    <>
                      <>
                        {senderZipCodeSuccess && !isZipCodeEmpty?.sender_zip_code && (
                          <FaCheckCircle color="green" />
                        )}
                      </>
                      <>
                        {(senderZipCodeLoading && !isZipCodeEmpty?.sender_zip_code) && (
                          <Spinner size="sm" color="warning" />
                        )}
                      </>
                      <>{(senderZipCodeError && !isZipCodeEmpty?.sender_zip_code) && <FaCircleXmark color="red" />}</>
                    </>
                  }
                  classNames={{
                    label: [
                      "text-[14px] lg:text-[17px] font-inter text-[#596066] font-semibold pt-1 pb-0",
                    ],
                    input: [
                      "bg-transparent ",
                      "text-[12px] lg:text-[15px]  font-inter text-[#AEAEAE] font-medium",
                      "placeholder:text-[12px] lg:placeholder:text-[15px] placeholder:font-inter placeholder:text-[#999999] placeholder:font-medium pb-2 pt-1",
                    ],

                    inputWrapper: [
                      "border-none shadow-none h-[40px] lg:h-[61px]",
                    ],
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="my-3 lg:my-5">
          <div className="bg-white rounded-[10px] flex items-center px-2 border-1 border-[#EEEEEE]">
            <div className="w-[28px] h-[28px] lg:h-[40px] lg:w-[40px]  rounded-full bg-[#FEF5E0] flex items-center justify-center">
              <LocationIcon />
            </div>
            <div className="flex items-center">
              <Controller
                name="receiver_country"
                control={control}
                defaultValue={
                  shippingState?.data?.shippingInformation?.form?.receiver
                    ?.country_code ?? "US"
                }
                render={({ field: { onChange, value } }) => (
                  <ReactFlagsSelect
                    key={"receiver_country"}
                    selected={value}
                    onSelect={(countryCode) => {
                      onChange(countryCode);
                      let checkZipCodeStatus =
                        checkIsZipCodeRequired(countryCode);
                      setZipCoderequirements((prev: any) => ({
                        ...prev,
                        receiverZipCode: checkZipCodeStatus,
                      }));
                      let getCountry = getPhoneCode(countryCode);
                      setValue("receiver", {
                        country: getCountry?.name,
                        country_code: countryCode,
                        phone_number: getCountry?.dial_code,
                      });
                      setValue("receiver_zip_code", "");
                      // Add any additional logic you need here
                    }}
                    fullWidth={!zipCodeRequirements?.receiverZipCode}
                    showSelectedLabel={!zipCodeRequirements?.receiverZipCode}
                    placeholder=""
                    searchable={true}
                    className="bg-[#fff] rounded-lg focus:outline-none border-0 country_selection_no_border"
                  />
                )}
              />
              {zipCodeRequirements?.receiverZipCode && (
                <Input
                  {...register("receiver_zip_code", { required: true })}
                  type="text"
                  isRequired
                  aria-label="Receiver/To"
                  autoComplete="off"
                  variant="bordered"
                  onChange={(e) =>
                    handleChangeZipCode(e.target.value, "receiver_country")
                  }
                  endContent={
                    <>
                      <>
                        {receiverZipCodeSuccess && !isZipCodeEmpty?.receiver_zip_code && (
                          <FaCheckCircle color="green" />
                        )}
                      </>
                      <>
                        {receiverZipCodeLoading  && !isZipCodeEmpty?.receiver_zip_code && (
                          <Spinner size="sm" color="warning" />
                        )}
                      </>
                      <>
                        {receiverZipCodeError  && !isZipCodeEmpty?.receiver_zip_code && <FaCircleXmark color="red" />}
                      </>
                    </>
                  }
                  placeholder="Receiver zip code"
                  classNames={{
                    label: [
                      "text-[14px] lg:text-[17px] font-inter text-[#596066] font-semibold pt-1",
                    ],
                    input: [
                      "bg-transparent ",
                      "text-[12px] lg:text-[15px]  font-inter text-[#AEAEAE] font-medium",
                      "placeholder:text-[12px] lg:placeholder:text-[15px] placeholder:font-inter placeholder:text-[#999999] placeholder:font-medium pb-2 pt-1",
                    ],

                    inputWrapper: [
                      "border-none shadow-none h-[40px] lg:h-[61px]",
                    ],
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {isProductTypeSuccess && (
          <div className="my-3 lg:my-5">
            <div className="bg-white rounded-[10px] flex items-center px-2 gap-[70px] border-1 border-[#EEEEEE]">
              <div className="w-[28px] h-[28px]  lg:w-[40px]  lg:h-[40px] rounded-full bg-[#FEF5E0] flex items-center justify-center">
                <ProductBox />
              </div>
              <Select
                variant="bordered"
                {...register("product_type", { required: true })}
                isRequired={true}
                // onChange={(e) => handleProductType(e?.target?.value)}
                classNames={{
                  base: ["border-none"],
                  label: [
                    "text-[14px] lg:text-[17px] font-plus_jakarta text-[#596066] font-semibold pt-1",
                  ],
                  trigger: [
                    "text-[12px] lg:text-[15px] font-inter text-[#999999] border-none shadow-none h-[40px] lg:h-[61px]",
                  ],
                  value: [
                    "text-[12px] lg:text-[15px] font-inter text-[#999999] font-medium pb-2",
                  ],
                  listboxWrapper: "max-h-[200px]",
                  listbox: [
                    "text-[12px] font-inter text-[#999999] font-medium",
                  ],
                }}
                items={
                  isProductTypeSuccess
                    ? productType?.data?.product_category_list
                    : []
                }
                placeholder="Select product type"
                selectorIcon={<FaChevronDown />}
              >
                {(product: any) => (
                  <SelectItem key={product?.id} value={product?.id}>
                    {product?.name}
                  </SelectItem>
                )}
              </Select>
            </div>
          </div>
        )}

        {watch("product_type") && (
          <>
            <div className="my-3 lg:my-5">
              <div className="bg-white rounded-[10px] flex items-center px-2 gap-[70px] border-1 border-[#EEEEEE]">
                <div className="w-[24px] lg:w-[40px] h-[24px] lg:h-[40px] rounded-full bg-[#FEF5E0] flex items-center justify-center">
                  <WeightMeter />
                </div>
                <Input
                  {...register("weight", { required: true })}
                  autoComplete="off"
                  type="text"
                  variant="bordered"
                  placeholder="Type weight"
                  isRequired={true}
                  classNames={{
                    label: [
                      "text-[14px] lg:text-[17px] font-inter text-[#596066] font-semibold pt-1",
                    ],
                    input: [
                      "bg-transparent ",
                      "text-[12px] lg:text-[15px]  font-inter text-[#AEAEAE] font-medium",
                      "placeholder:text-[12px] lg:placeholder:text-[15px] placeholder:font-inter placeholder:text-[#999999] placeholder:font-medium pt-1 pb-2",
                    ],

                    inputWrapper: [
                      "border-none shadow-none h-[40px] lg:h-[61px]",
                    ],
                  }}
                  endContent={
                    <select
                      className="text-[10px] text-[#596066]
                        font-plus_jakarta font-semibold"
                      onChange={(e) => handleWeightType(e?.target?.value)}
                    >
                      <option value="kg" selected>
                        KG
                      </option>
                      <option value="lbs">LBS</option>
                    </select>
                  }
                />
              </div>
              {errors.weight && (
                <p className="text-[10px] font-plus_jakarta text-red-500 px-3 py-[2px]">
                  This field is required
                </p>
              )}
            </div>
            {!largePackage && (
              <p className="text-[12px] lg:text-[13px] font-poppins text-[#545454] my-6">
                If your package&apos;s length + width + height is bigger than 72
                inches or 182 cm, please 
                <a
                  href="javascript:void(0)"
                  onClick={() => setLargePackage(true)}
                  className="text-[13px] text-[#0abde3] underline cursor-pointer"
                >
                  Click here
                </a>
              </p>
            )}
          </>
        )}

        {largePackage && (
          <div>
            <div className="my-3 lg:my-5">
              <div className="bg-white rounded-[10px] flex items-center px-2 py-1 gap-[70px] border-1 border-[#EEEEEE]">
                <div className="w-[24px] h-[24px] lg:w-[40px]  lg:h-[40px] rounded-full bg-[#FEF5E0] flex items-center justify-center">
                  <WeightMeter />
                </div>
                <Input
                  type="text"
                  variant="bordered"
                  {...register("box_length")}
                  autoComplete="off"
                  isRequired={true}
                  placeholder="Box length here..."
                  classNames={{
                    label: [
                      "text-[14px] lg:text-[17px] font-plus_jakarta text-[#596066] font-semibold pt-1 pb-0",
                    ],
                    input: [
                      "bg-transparent focus:bg-white",
                      "text-[12px] lg:text-[15px]  font-inter text-[#AEAEAE] font-medium",
                      "placeholder:text-[12px] lg:placeholder:text-[15px] placeholder:font-inter placeholder:text-[#999999] placeholder:font-medium pb-2 p-1",
                    ],

                    inputWrapper: [
                      "border-none shadow-none h-[40px] lg:h-[51px] bg-white",
                    ],
                  }}
                  endContent={
                    <p
                      className="text-[12px] text-[#596066]
                        font-plus_jakarta font-semibold"
                    >
                      {weightType === "kg" ? "In" : "Cm"}
                    </p>
                  }
                />
              </div>
              {errors.length && (
                <p className="text-[10px] font-plus_jakarta text-red-500 px-3 py-[2px]">
                  This field is required
                </p>
              )}
            </div>
            <div className="my-3 lg:my-5">
              <div className="bg-white rounded-[10px] flex items-center px-2 py-1 gap-[70px] border-1 border-[#EEEEEE]">
                <div className="w-[24px] h-[24px] lg:w-[40px]  lg:h-[40px] rounded-full bg-[#FEF5E0] flex items-center justify-center">
                  <WeightMeter />
                </div>
                <Input
                  type="text"
                  variant="bordered"
                  autoComplete="off"
                  {...register("box_width", { required: true })}
                  isRequired={true}
                  placeholder="Box width here..."
                  classNames={{
                    label: [
                      "text-[14px] lg:text-[17px] font-plus_jakarta text-[#596066] font-semibold pt-1 pb-0",
                    ],
                    input: [
                      "bg-transparent ",
                      "text-[12px] lg:text-[15px]  font-inter text-[#AEAEAE] font-medium",
                      "placeholder:text-[12px] lg:placeholder:text-[15px] placeholder:font-inter placeholder:text-[#999999] placeholder:font-medium pb-2 pt-1",
                    ],

                    inputWrapper: [
                      "border-none shadow-none h-[40px] lg:h-[51px]",
                    ],
                  }}
                  endContent={
                    <p
                      className="text-[12px] text-[#596066]
                      font-plus_jakarta font-semibold"
                    >
                      {weightType === "kg" ? "In" : "Cm"}
                    </p>
                  }
                />
              </div>
              {errors.width && (
                <p className="text-[10px] font-plus_jakarta text-red-500 px-3 py-[2px]">
                  This field is required
                </p>
              )}
            </div>
            <div className="my-3 lg:my-5">
              <div className="bg-white rounded-[10px] flex items-center px-2 py-1 gap-[70px] border-1 border-[#EEEEEE]">
                <div className="w-[24px] h-[24px] lg:w-[40px]  lg:h-[40px] rounded-full  flex items-center justify-center">
                  <WeightMeter />
                </div>
                <Input
                  type="text"
                  autoComplete="off"
                  variant="bordered"
                  {...register("box_height")}
                  isRequired={true}
                  placeholder="Box height here..."
                  classNames={{
                    label: [
                      "text-[14px] lg:text-[17px] font-plus_jakarta text-[#596066] font-semibold pt-1 pb-0",
                    ],
                    input: [
                      "bg-transparent fo",
                      "text-[12px] lg:text-[15px]  font-inter text-[#AEAEAE] font-medium",
                      "placeholder:text-[12px] lg:placeholder:text-[15px] placeholder:font-inter placeholder:text-[#999999] placeholder:font-medium pb-2 pt-1",
                    ],

                    inputWrapper: [
                      "border-none shadow-none h-[40px] lg:h-[51px]",
                    ],
                  }}
                  endContent={
                    <p
                      className="text-[12px] text-[#596066]
                      font-plus_jakarta font-semibold"
                    >
                      {weightType === "kg" ? "In" : "Cm"}
                    </p>
                  }
                />
              </div>
              {errors.height && (
                <p className="text-[10px] font-plus_jakarta text-red-500 px-3 py-[2px]">
                  This field is required
                </p>
              )}
            </div>
          </div>
        )}
        {isProductTypeSuccess && (
          <div className="my-5">
            <Button
              type="submit"
              size="lg"
              className="w-full bg-[#f6cc46]
              text-[15.6px] text-[#333333] hover:bg-gradient-to-t hover:from-[#FBD306] hover:to-[#ece35b]
               font-plus_jakarta font-bold rounded-[10px]"
              endContent={<ButtonArrowRight />}
            >
              Get Quote
            </Button>
          </div>
        )}
      </form>
      <ShippingMethod
        isOpen={isOpen}
        onClose={onClose}
        isLoadingCourierList={isLoadingCourierList}
        isSucessShipPriceList={isSucessShipPriceList}
        courierList={courierList}
      />
    </>
  );
};

export default Ship;
