import { useAppDispatch, useAppSelector } from "@/config/hooks";
import { RootState } from "@/config/store";
import {
  updatePickupRequest,
  updateShippingMethod,
} from "@/features/shipping/Shipping.slice";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Progress,
  Skeleton,
  useDisclosure,
} from "@nextui-org/react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";
type TProps = {
  isOpen: any;
  isLoadingCourierList: boolean;
  isSucessShipPriceList: boolean;
  courierList: any;
  onClose: any;
};
const ShippingMethod = ({
  isOpen,
  isLoadingCourierList,
  isSucessShipPriceList,
  courierList,
  onClose,
}: TProps) => {
  const router = useRouter();
  const stateData = useAppSelector((state: RootState) => state.shipping);
  const dispatch = useAppDispatch();
  const handleSelectCourier = (courier: any) => {
    if (stateData?.data?.shippingMethod?.courier_id !== courier?.courier_id) {
      dispatch(updatePickupRequest({}));
    }
    dispatch(updateShippingMethod(courier));
    router.push("/login");
    console.log(stateData?.data?.shippingMethod);
  };
console.log('courierList',courierList);
console.log('isSucessShipPriceList',isSucessShipPriceList);
  return (
    <>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-[18px] font-plus_jakarta">
                Select Carrier
              </ModalHeader>
              <ModalBody>
                <div className="">
                  {/*******Courier Header**********/}
                  <div className="hidden lg:grid grid-cols-2   gap-2 font-poppins text-[12px] font-medium bg-gray_light items-center px-5 py-3 rounded-lg">
                    <div className="col-span-1">
                      <div className="grid grid-cols-2">
                        <div className="flex col-span-1">
                          <p className="text-slate-500">Courier</p>
                        </div>
                        <div className="flex col-span-1">
                          <p className="text-slate-500">Delivery Time</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="grid grid-cols-3">
                        <div className="flex col-span-1">
                          <p className="text-slate-500">Import duty</p>
                        </div>
                        <div className="flex col-span-1">
                          <p className="text-slate-500">Shipping Charge</p>
                        </div>
                        <div className="flex col-span-1">
                          <p className="text-slate-500 pl-4">Action</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*******Courier Body**********/}
                  {isLoadingCourierList && (
                    <div className="flex lg:grid lg:grid-cols-2 justify-between mt-2 font-poppins text-[12px] font-medium  items-center p-3 rounded-lg shadow-lg">
                      <div className="lg:col-span-1">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                          <div className="flex col-span-1 py-3 items-center">
                            <Skeleton className="rounded-lg">
                              <div className="h-16 w-52 rounded-lg bg-default-300"></div>
                            </Skeleton>
                            <Skeleton className="w-3/5 rounded-lg">
                              <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                          </div>
                          <div className="col-span-1 py-3">
                            <Skeleton className="rounded-lg my-3">
                              <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="rounded-lg my-3">
                              <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-1">
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 items-center">
                          <div className="flex lg:col-span-1">
                            <Skeleton className="w-3/5 rounded-lg">
                              <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                          </div>
                          <div className="flex lg:col-span-1">
                            <Skeleton className="w-3/5 rounded-lg">
                              <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                          </div>
                          <div className="flex lg:col-span-1">
                            <Skeleton className="rounded-lg">
                              <div className="h-6 w-10 rounded-lg bg-default-200"></div>
                            </Skeleton>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="max-h-[500px] overflow-y-scroll">
                    {isSucessShipPriceList &&
                      courierList?.data?.length > 0 &&
                      courierList?.data?.map((courier: any, index: any) => (
                        <div
                          key={index}
                          className="flex max-h-[500px] lg:grid lg:grid-cols-2 justify-between mt-2 font-poppins text-[12px] font-medium  items-center p-3 rounded-lg shadow-lg"
                        >
                          <div className="lg:col-span-1">
                            <div className="grid grid-cols-1 lg:grid-cols-2">
                              <div className="flex col-span-1 py-3 items-center">
                                <Image
                                  src={courier?.logo}
                                  width={45}
                                  height={45}
                                  alt={courier?.courier_name}
                                />
                                <p className="text-[13px] font-medium font-poppins text-black pl-6">
                                  {courier.courier_name}
                                </p>
                              </div>
                              <div className="col-span-1 py-3">
                                <Progress
                                  color="primary"
                                  aria-label="Loading..."
                                  value={courier.delivery_time}
                                  classNames={{
                                    base: "max-w-[150px]",
                                    indicator:
                                      "bg-gradient-to-r from-blue-500 to-blue-600",
                                  }}
                                />
                                <p className="max-w-[150px] lg:max-w-full text-[13px] font-medium font-poppins text-slate-500 pt-2">
                                  {courier.delivery_time_text}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="lg:col-span-1">
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 items-center">
                              <div className="flex lg:col-span-1">
                                <p className="text-[13px] font-medium font-poppins text-slate-500">
                                  {courier.import_tax}
                                </p>
                              </div>
                              <div className="flex lg:col-span-1">
                                <span className="bg-slate-300 py-2 px-3 rounded-2xl">
                                  <p className="text-[13px] font-medium font-poppins">
                                    ${courier.shipping_charge?.toFixed(2)}USD
                                  </p>
                                </span>
                              </div>
                              <div className="flex lg:col-span-1">
                                <Button
                                  onClick={() => handleSelectCourier(courier)}
                                  size="lg"
                                  className="text-[13px] font-medium font-poppins  text-black hover:bg-yellow-300 duration-300"
                                  variant="flat"
                                >
                                  Select
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

{
                        !isSucessShipPriceList && !isLoadingCourierList && ( <div
                          className="flex max-h-[500px] lg:grid  justify-center mt-2 font-poppins text-[12px] font-medium  items-center p-3 rounded-lg shadow-lg"
                        >
                       
                             <div>
                              <p className="text-center">No Courier found. please try different combination</p>
                           
                          </div>

                         
                        </div>)
                      }
                  </div>
                 
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShippingMethod;
