import { getHeader } from "@/utils/headers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const shipApi = createApi({
  reducerPath: "shipApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
    prepareHeaders: (headers, { getState }) => { return getHeader(headers, getState) },
  }),
  endpoints: (builder) => ({
    // getZipCode: builder.mutation({
    //   query: (body) => {
    //     return {
    //       url: "customer/location/zip-code",
    //       method: "post",
    //       body: body,
    //     };
    //   },
    // }),
    getShipPrice: builder.mutation({
      query: (body) => {
        return {
          url: "ship/courier/check-price",
          method: "post",
          body: body,
        };
      },
    }),
    getProductCategory: builder.query({
      query: () => 'ship/product-category-list',
    }),
    
  }),
});


export const {
  useGetProductCategoryQuery,
  useGetShipPriceMutation
} = shipApi;
