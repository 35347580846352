"use client";
import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import React, { useState } from "react";
import { TravelerIcon } from "@/icons/shipping/CourierIcon";
import CartIcon from "@/icons/website-icon/CartIcon";
import FastDeliveryIcon from "@/icons/website-icon/FastDeliveryIcon";
import { useAppSelector } from "@/config/hooks";
import { RootState } from "@/config/store";
import { useFieldArray, useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import Ship from "./Ship";
import { useRouter, useSearchParams } from "next/navigation";

type BuyForMeLink = {
  products: {
    link: string;
    image?: string;
    description?: string;
    title?: string;
  }[];
};
const ShippingRateCal = () => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState("Ship");
  const [activeShopTab, setActiveShopTab] = useState("buy_for_me");
  const shopTabs = [
    {
      id: 1,
      title: "Buy for me",
      key: "buy_for_me",
    },
    {
      id: 2,
      title: "Ship for me",
      key: "ship_for_me",
    },
  ];
  const tabs = [
    {
      id: 1,
      title: "Ship",
      icon: <FastDeliveryIcon />,
    },
    {
      id: 2,
      title: "Shop",
      icon: <CartIcon />,
    },
    {
      id: 3,
      title: "Track",
      icon: <TravelerIcon />,
    },
  ];

  let productLinkCount = 1;
  const currentState = useAppSelector(
    (state: RootState) => state.shopping.data
  );
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<BuyForMeLink>({
    defaultValues: {
      products: currentState.buyProductsLinksInfo,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
    rules: {
      required: "Please input at least a link",
    },
  });
  const onSubmitProductLink = (data: BuyForMeLink) => {
    console.log(data);
  };
  const handleTrack = (e: any) => {
    e.preventDefault();
    let trackingNo: string = e.target.trackingInput.value;
    router.push(`/track/?tracking-number=${trackingNo}`);
  };
  return (
    <>
      <div
        className="max-w-[523px]  backdrop-grayscale-0
           bg-[#F1F5F9]/70 rounded-[10px] px-4 py-3 relative shadow-md w-full"
      >
        <div
          className="flex mx-4 lg:mx-10 bg-white px-3 justify-center absolute -top-6 left-0 right-0 
            rounded-[10px] border-1 lg:border-2 border-[#E8F1FF]  min-h-[40px] lg:min-h-[68px]"
        >
          <ul className="flex items-center gap-8">
            {tabs?.map((tab) => (
              <li
                key={tab?.id}
                className={`flex items-center gap-3 text-[#828282] text-[14px] lg:text-[18px] 
                  font-inter cursor-pointer shiper-tab-item relative ${
                    activeTab === tab?.title ? "active-shiper-tab-item" : ""
                  }
                  after:-bottom-2 lg:after:-bottom-[18px] grayscale-0 hover:grayscale-0`}
                onClick={() => setActiveTab(tab?.title)}
              >
                {tab?.icon} {tab?.title}
              </li>
            ))}
          </ul>
        </div>
        {/************Ship */}
        {activeTab === "Ship" && <Ship />}
        {/************Shop */}
        {activeTab === "Shop" && (
          <div className="mt-7 lg:mt-16 lg:min-w-[488px]">
            <div className="flex items-center justify-center gap-5">
              {shopTabs?.map((shopTab) => (
                <div
                  key={shopTab?.id}
                  onClick={() => setActiveShopTab(shopTab?.key)}
                  className={`
                      hover:text-blue-500 cursor-pointer text-[12px]
                     lg:text-[16px] font-plus_jakarta font-medium 
                     relative after:-bottom-[5px] shop-tab-item
                      ${
                        activeShopTab === shopTab?.key
                          ? "active text-blue-500"
                          : "text-slate-500"
                      }`}
                >
                  {shopTab?.title}
                </div>
              ))}
            </div>
            <div className="my-6">
              {/**********Buy For Me */}
              {activeShopTab === "buy_for_me" && (
                <form
                  onSubmit={handleSubmit(onSubmitProductLink)}
                  className="px-2 lg:px-0 w-full lg:w-[88%] mx-auto"
                >
                  <p className="text-[12px] lg:text-[14px] font-plus_jakarta font-medium text-black leading-3">
                    Select Product
                  </p>
                  {fields.map((item: any, index: number) => (
                    <div
                      key={item.id}
                      className="flex items-center justify-center gap-[2px] lg:gap-1 my-4 "
                    >
                      <div className="w-full">
                        <div className="flex border-1 items-center justify-center border-[#CCE1FF] bg-white rounded-full lg:py-[6px] relative">
                          <div className="w-[80px] h-[20px] lg:w-[100px] lg:h-[45px] flex justify-center rounded-3xl bg-[#D9E5E3] items-center relative mx-2">
                            <h3 className="text-black text-[8px] lg:text-[10px] font-plus_jakarta font-semibold lg:text-center">
                              Product {productLinkCount++}
                            </h3>
                          </div>
                          <div className="w-full">
                            <Input
                              type="text"
                              {...register(`products.${index}.link`, {
                                required: true,
                              })}
                              placeholder="Copy & paste your product link:  https://www.amazon.com"
                              classNames={{
                                input: [
                                  "base:bg-white",
                                  "label:text-[#596066]  text-[9px] lg:text-[12px] text-[12px] font-semibold font-plus_jakarta",
                                  "placeholder:text-[#A9A9A9] text-[9px] lg:text-[12px] font-semibold font-plus_jakarta",
                                ],
                                inputWrapper: [
                                  "bg-white",
                                  "shadow-none",
                                  "rounded-3xl",
                                ],
                                innerWrapper: ["py-5"],
                              }}
                            />
                          </div>
                          <div className="mx-1">
                            <div className="w-[30px] h-[30px] lg:w-[35px] lg:h-[35px] flex items-center justify-center rounded-full border-1 border-[#DFDFDF] bg-[#F1F1F1] relative ">
                              <div className="text-[#A1A1A1] text-sm font-plus_jakarta font-semibold">
                                ?
                              </div>
                            </div>
                          </div>
                          {index !== 0 && (
                            <div
                              onClick={() => remove(index)}
                              className="w-[23px] h-[23px] bg-[#AAFFFF] rounded-full absolute -top-4 right-2 flex items-center justify-center
                          cursor-pointer"
                            >
                              <RxCross2 />
                            </div>
                          )}
                        </div>
                        {errors.products?.[index]?.link && (
                          <p className="text-[11px] text-red-400 font-plus_jakarta">
                            Please enter your product link
                          </p>
                        )}
                      </div>
                    </div>
                  ))}

                  <div className="flex my-2">
                    <div
                      onClick={() =>
                        append({
                          link: "",
                        })
                      }
                      className="text-slate-500 text-[11px] lg:text-[12px] font-plus_jakarta font-semibold cursor-pointer"
                    >
                      + Add another link
                    </div>
                  </div>
                  <div className="flex">
                    <Button
                      type="submit"
                      className="w-full rounded-[88px] text-sm font-plus_jakarta font-bold drop-shadow-md
                bg-gradient-to-b from-[#F6D448] to-[#F4BA40] py-3 px-1 text-center
                hover:from-[#F6D448] hover:to-[#bd9030]"
                    >
                      Confirm
                    </Button>
                  </div>
                </form>
              )}
              {/**********Ship For Me */}
              {activeShopTab === "ship_for_me" && (
                <div className="px-2 lg:px-0 min-w-full lg:w-[88%] mx-auto">
                  <form className="w-full" action={'/dashboard/shopping/create-parcel'}>
                    <Button
                      type="submit"
                      className="w-full rounded-[88px] text-sm font-plus_jakarta font-bold drop-shadow-md
                bg-gradient-to-b from-[#F6D448] to-[#F4BA40] py-3 px-1 text-center
                hover:from-[#F6D448] hover:to-[#bd9030]"
                    >
                      Get Address
                    </Button>
                  </form>
                </div>
              )}
            </div>
          </div>
        )}
        {/**********Track For Me */}
        {activeTab === "Track" && (
          <div className="mt-7 lg:mt-10  lg:min-w-[488px]">
            <form className="w-full lg:w-[88%] mx-auto" onSubmit={handleTrack}>
              <label
                htmlFor="tracking"
                className="text-[16px] font-plus_jakarta font-medium"
              >
                Tracking ID
              </label>
              <Input
                id="tracking"
                type="text"
                placeholder="Enter your tracking ID"
                variant="bordered"
                size="lg"
                radius="sm"
                name="trackingInput"
                // required
                classNames={{
                  input: [
                    "bg-transparent py-5",
                    "hover:bg-transparent",
                    "placeholder:text-gray dark:placeholder:text-white/60 font-plus_jakarta text-[14px] font-semibold",
                  ],
                  innerWrapper: ["bg-white", "hover:bg-white"],
                  inputWrapper: [
                    "bg-white",
                    "hover:bg-white",
                    "shadow-none",
                    "border-none",
                  ],
                }}
              />
              <div className="flex my-5">
                <Button
                  type="submit"
                  size="lg"
                  className="w-full text-sm font-plus_jakarta font-bold drop-shadow-md
                bg-gradient-to-b from-[#F6D448] to-[#F4BA40] py-3 px-1 text-center rounded-[8px]
                hover:from-[#F6D448] hover:to-[#bd9030]"
                >
                  Track
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default ShippingRateCal;
