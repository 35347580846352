import React from "react";

export const ProductBox = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#D1FAFF" />
        <path
          d="M11.17 15.4399L20 20.5499L28.77 15.4699"
          stroke="#009AAD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 29.61V20.54"
          stroke="#009AAD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.93 10.48L12.59 13.45C11.38 14.12 10.39 15.8 10.39 17.18V22.83C10.39 24.21 11.38 25.89 12.59 26.56L17.93 29.53C19.07 30.16 20.94 30.16 22.08 29.53L27.42 26.56C28.63 25.89 29.62 24.21 29.62 22.83V17.18C29.62 15.8 28.63 14.12 27.42 13.45L22.08 10.48C20.93 9.84 19.07 9.84 17.93 10.48Z"
          stroke="#009AAD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 21.2401V17.5801L15.51 12.1001"
          stroke="#009AAD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
