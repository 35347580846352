export const ButtonArrowRight = () => {
  return (
    <svg
      width="59"
      height="23"
      viewBox="0 0 59 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.3077 12.4112C58.8934 11.8255 58.8934 10.8757 58.3077 10.2899L48.7617 0.743984C48.1759 0.158197 47.2262 0.158197 46.6404 0.743984C46.0546 1.32977 46.0546 2.27952 46.6404 2.8653L55.1257 11.3506L46.6404 19.8359C46.0546 20.4217 46.0546 21.3714 46.6404 21.9572C47.2262 22.543 48.1759 22.543 48.7617 21.9572L58.3077 12.4112ZM0.666992 12.8506H57.247V9.85059H0.666992V12.8506Z"
        fill="black"
      />
    </svg>
  );
};
export const LearnBtnRightArrowIcon = (props: any) => (
  <svg
    {...props}
    width="43"
    height="8"
    viewBox="0 0 43 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.3536 4.26273C42.5488 4.06747 42.5488 3.75089 42.3536 3.55563L39.1716 0.373646C38.9763 0.178384 38.6597 0.178384 38.4645 0.373646C38.2692 0.568908 38.2692 0.88549 38.4645 1.08075L41.2929 3.90918L38.4645 6.73761C38.2692 6.93287 38.2692 7.24945 38.4645 7.44471C38.6597 7.63998 38.9763 7.63998 39.1716 7.44471L42.3536 4.26273ZM0 4.40918H42V3.40918H0V4.40918Z"
      fill="#333333"
    />
  </svg>
);
